<template>
    <div>
      <div>
        <search @changeNetwork="changeNetwork"></search>
      </div>

      <b-row>
        <b-col class="col-12 col-lg-12">
          <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100" ref="filter_audience_location">
            <template #button-content> 
              <div class="d-flex flex-column justify-content-between h-100">
                <h4 class="text-left font-weight-bold m-0">Selecciones destacadas de influencers en: {{country.name}}</h4>
              </div>
            </template>
            <b-dropdown-header :key="update_location">
              <div v-for="(location, index) in audience_locations" :key="index">
                <audience-location-filter
                  :key="change_audience_location"
                  :show_remove="false"
                  :show_slider="false"
                  :index="index"
                  :location="location"
                  :only_countries="true"
                  :show_reset_location="false"
                  :show_loader="false"
                  @changeLocationAudienceChild="changeLocationAudience"
                />
              </div>
            </b-dropdown-header>
          </b-dropdown>
        </b-col>
      </b-row>

      <b-row>
        <div class="container-loader-searcher loader-table-cards" v-if="loader_location==true">
          <b-spinner class="spinner-table-searcher"></b-spinner>
        </div>
        <b-col class="col-12 col-lg-6 mt-1" v-for="card in cards" :key="card.hash" @click="searcher(card)">
          <card-secondary
            class="cursor-pointer"
            :textInfo="card.title"
            :colorCard="card.color"
            :images="card.avatars"
          >
          </card-secondary>
        </b-col>
        
      </b-row>
    </div>
</template>

<script>
import {
  BSpinner,
  BRow,
  BCol,
  BDropdown,
  BDropdownHeader
} from "bootstrap-vue";
import { getUrlCategory } from '@/libs/utils/urls';
import { getMainInfluencers } from '@/services/search'

export default {
  name: 'analyzerDashboard',
  components: {
    BSpinner,
    BRow,
    BCol,
    BDropdown,
    BDropdownHeader,
    Search: () => import('@/views/search/search.vue'),
    cardSecondary: () => import('@/views/pages/analyzer/cardSecondary.vue'),
    AudienceLocationFilter: () => import('@/views/buscador/filters/audience_locations.vue')
  },
  data() {
    return {
      loader_location: false,
      network: '1',
      networks: [
        '1',
        '3',
        '5',
        '2',
        '4'
      ],
      sort_options: [
        {key: 'most_followed', sort_type: 'fc', sort_order: 'desc'},
        {key: 'fast_growing', sort_type: 'sbs_grth', sort_order: 'desc'},
        {key: 'most_commented', sort_type: 'cr', sort_order: 'desc'},
        {key: 'influencers', sort_type: 'fc', sort_order: 'desc'},
        {key: 'best_quality', sort_type: 'er', sort_order: 'desc'},
        {key: 'best_quality_2', sort_type: 'aqs', sort_order: 'desc'},
        {key: 'most_liked', sort_type: 'likes', sort_order: 'desc'},
        {key: 'fast_growing_women', sort_type: 'sbs_grth', sort_order: 'desc'},
        {key: 'fast_growing_men', sort_type: 'sbs_grth', sort_order: 'desc'},
        {key: 'most_er', sort_type: 'er', sort_order: 'desc'},
        {key: 'most_viewed', sort_type: 'pv_a', sort_order: 'desc'},
        {key: 'most_streamed', sort_type: 'hours_streamed', sort_order: 'desc'},
        {key: 'most_viewed_4', sort_type: 'live_viewers_avg', sort_order: 'desc'},
      ],
      getUrlCategory,
      main_country: 3996063,
      cards: [],
      update_location: false,
      audience_locations: [{}],
      change_audience_location: false,
      main_influencers: [],
      country: {
        id: 3996063,
        name: 'Mexico',
        type: 0
      }
    }
  },
  created() {
    this.cards = [];
    this.getMainInfluencers()
  },
  destroyed() {
  },
  methods: {
    changeNetwork(network) {
      this.network = this.networks[network]
      this.getMainInfluencers()
      this.formatCards()
    },
    changeLocationAudience(data) {
      this.$refs.filter_audience_location.$el.querySelector('button').click()
      this.country = data.location
      this.getMainInfluencers()
    },
    getMainInfluencers() {
      this.loader_location = true;
      getMainInfluencers(this.country.id).then(response => {
        this.main_influencers = response.searches
        this.formatCards()
      })
    },
    formatCards() {
      this.cards = []
      this.main_influencers[this.network].forEach(item => {
        this.cards.push(item);
      })
      this.loader_location = false;
    },
    searcher(card) {
      const exist_locations = Object.keys(card.search).find(k => k === 'audience_location')

      if (exist_locations) {
        card.search['audience_location'][0]['name'] = this.country.name
      }

      const exist_influencer_locations = Object.keys(card.search).find(k => k === 'influencer_location')

      if (exist_influencer_locations) {
        card.search['influencer_location'][0]['name'] = this.country.name
      }

      let slug = card.slug

      if (this.network === '4' && slug === 'most_viewed') slug = `${slug}_4`
      if (this.network === '2' && slug === 'best_quality') slug = `${slug}_2`

      const sort = this.sort_options.find(s => s.key === slug)

      if (sort) {
        card.search['sort_type'] = sort.sort_type
        card.search['sort_order'] = sort.sort_order
      } else {
        card.search['sort_type'] = 'sbs_grth'
        card.search['sort_order'] = 'desc'
      }

      const search = JSON.stringify(card.search);

      const hash = btoa(search);

      this.$router.push({name: 'auth-search', query: {hash}});
    }
  },
} 
</script>

<style scoped>
.container-loader-searcher {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  right: 5px;
}
.loader-table-cards {
  top: 0px;
  z-index: 10;
  display: flex;
  justify-content: center;
}
.spinner-table-searcher {
  margin-top: 30em;
  height: 6em;
  width: 6em;
  color: #acacac;
}
</style>